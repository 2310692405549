<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-col
            v-if="isMobile()"
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-space_between mb-1 mb-md-0"
          >
            <div class="bulk_selection d-flex">
              <div class="action-left pt-50">
                <b-form-checkbox
                  :checked="selectAssignedServiceRequestCheckbox"
                  :indeterminate="isSelectAssignedServiceRequestCheckboxIndeterminate"
                  @change="selectAllCheckboxUpdate"
                >
                  Select All
                </b-form-checkbox>
              </div>
              <b-dropdown
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mx-1"
                right
                text="Actions"
                variant="primary"
                :disabled="!selectedServiceRequests.length"
              >
                <b-dropdown-item
                  v-if="canViewThisAction('update', 'ServiceRequest')"
                  v-b-modal.modal-update-status-assigned-service-request-bulk
                >
                  Update Status
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="canViewThisAction('delete', 'ServiceRequest')"
                  @click="deleteBulkServiceRequests"
                >
                  Delete Service Request(s)
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="canViewThisAction('update', 'ServiceRequest')"
                  v-b-modal.modal-update-assignee-open-service-request-bulk
                >
                  Assign Selected Request(s)
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="canViewThisAction('update', 'ServiceRequest')"
                  v-b-modal.modal-update-payment-status-open-service-request-bulk
                >
                  Update Payment Status
                </b-dropdown-item>
              </b-dropdown>
              <b-form-checkbox
                :checked="groupByForm"
                class="mt-50 mr-1"
                @change="changeGroupByForm"
              >
                Group
              </b-form-checkbox>
            </div>
            <span class="text-muted">Showing <br>{{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>
          <!-- Per Page -->
          <b-col
            v-else
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div class="action-left pt-50">
              <b-form-checkbox
                :checked="selectAssignedServiceRequestCheckbox"
                :indeterminate="isSelectAssignedServiceRequestCheckboxIndeterminate"
                @change="selectAllCheckboxUpdate"
              >
                Select All
              </b-form-checkbox>
            </div>
            <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mx-1"
              right
              text="Bulk Actions"
              variant="primary"
              :disabled="!selectedServiceRequests.length"
            >
              <!-- <b-dropdown-item @click="downloadBulkCSV">
                Download CSV
              </b-dropdown-item> -->
              <b-dropdown-item
                v-if="canViewThisAction('update', 'ServiceRequest')"
                v-b-modal.modal-update-status-assigned-service-request-bulk
              >
                Update Status
              </b-dropdown-item>
              <b-dropdown-item
                v-if="canViewThisAction('delete', 'ServiceRequest')"
                @click="deleteBulkServiceRequests"
              >
                Delete Service Request(s)
              </b-dropdown-item>
              <b-dropdown-item
                v-if="canViewThisAction('update', 'ServiceRequest')"
                v-b-modal.modal-update-assignee-open-service-request-bulk
              >
                Assign Selected Request(s)
              </b-dropdown-item>
              <b-dropdown-item
                v-if="canViewThisAction('update', 'ServiceRequest')"
                v-b-modal.modal-update-payment-status-open-service-request-bulk
              >
                Update Payment Status
              </b-dropdown-item>
            </b-dropdown>
            <b-form-checkbox
              :checked="groupByForm"
              class="mt-50 mr-1"
              @change="changeGroupByForm"
            >
              Group by Service Forms
            </b-form-checkbox>
            <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <a
                href="javascript:void(0)"
                class="nav-link nav-link-search"
                @click="showSearchBar = !showSearchBar"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="21"
                  class="mr-50"
                />
                <span class="align-middle">Search</span>
              </a>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="searchQuery = ''; showSearchBar = !showSearchBar"
                  />
                </b-input-group-append>
              </b-input-group>

              <b-dropdown
                id="dropdown-form-assigned-service-request"
                ref="filter_dropdown_assigned_service_request"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="dropdown-modal"
                right
                variant="link"
              >
                <template #button-content>
                  <feather-icon
                    icon="FilterIcon"
                    size="21"
                    class="align-middle mr-50"
                  />
                  <span class="mr-1">Filters</span>
                </template>
                <service-request-filters
                  :status-filter.sync="statusFilter"
                  :payment-status-filter.sync="paymentStatusFilter"
                  :request-status-filter.sync="requestStatusFilter"
                  :service-date-filter.sync="serviceDateFilter"
                  :status-options="statusOptions"
                  :request-type.sync="requestTypeFilter"
                  :request-type-options="requestTypeOptions"
                  :assignee.sync="assigneeFilter"
                  :assignee-options="assigneeOptions"
                  @emitHideFilter="hideFilter"
                  @emitShowFilter="showFilter"
                />
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
        <div v-if="serviceDateFilter || requestTypeFilter.length || assigneeFilter.length || statusFilter.length || paymentStatusFilter.length || requestStatusFilter.length">
          <hr class="dividerHR filter-divider">
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <ul
                class="list-inline d-inline-block mb-1"
              >
                <li
                  v-if="dueDateFilter"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="dueDateFilter = ''"
                  >
                    {{ dueDateFilter }}
                  </b-form-tag>
                </li>
                <li
                  v-if="serviceDateFilter"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="serviceDateFilter = ''"
                  >
                    {{ serviceDateFilter }}
                  </b-form-tag>
                </li>
                <li
                  v-for="requestTypeVal in requestTypeFilter"
                  :key="requestTypeVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromRequestTypeFilter(requestTypeVal)"
                  >
                    <span class="text-capitalize">{{ resolveRequestTypeName(requestTypeVal) }}</span>
                  </b-form-tag>
                </li>
                <li
                  v-for="assigneeVal in assigneeFilter"
                  :key="assigneeVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromAssigneeFilter(assigneeVal)"
                  >
                    <span class="text-capitalize">{{ resolveAssigneeName(assigneeVal) }}</span>
                  </b-form-tag>
                </li>
                <li
                  v-for="statusVal in statusFilter"
                  :key="statusVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromStatusFilter(statusVal)"
                  >
                    <span class="text-capitalize">{{ statusVal.replace("-", " ") }}</span>
                  </b-form-tag>
                </li>
                <li
                  v-for="statusVal in paymentStatusFilter"
                  :key="statusVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromPaymentStatusFilter(statusVal)"
                  >
                    <span class="text-capitalize">{{ statusVal.replace("-", " ") }}</span>
                  </b-form-tag>
                </li>
                <li
                  v-for="statusVal in requestStatusFilter"
                  :key="statusVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromRequestStatusFilter(statusVal)"
                  >
                    <span class="text-capitalize">{{ statusVal.replace("-", " ") }}</span>
                  </b-form-tag>
                </li>
                <li
                  class="list-inline-item"
                >
                  <span
                    class="text-danger clear-filter-text mb-50"
                    @click="clearAllFilters"
                  >
                    Clear Filters
                  </span>
                </li>
              </ul>
            </b-col>
          </b-row>
        </div>
      </div>

      <b-table
        v-if="groupByForm"
        ref="refAssignedServiceRequestsTable"
        class="position-relative has_padding special-group-table event_list min-height-200"
        :items="fetchServiceRequests"
        responsive
        :fields="formTableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        :tbody-tr-class="rowClass"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(serviceForm)="data">
          <div
            v-if="data.index == 0 || serviceRequestData[data.index - 1].serviceFormStringID != data.item.serviceFormStringID"
            class="text-nowrap"
          >
            <span class="">From</span>
            <br>
            <b-button
              v-if="canViewThisAction('show', 'ServiceForm')"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-primary align-middle detail-view-id"
              :to="{ name: 'operation-service-forms-show', params: { id: data.item.serviceForm._id } }"
            >
              {{ data.item.serviceFormStringID }}
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-primary align-middle detail-view-id not-button"
            >
              {{ data.item.serviceFormStringID }}
            </b-button>
          </div>
        </template>
        <!-- Column: Role -->
        <template #cell(stringID)="data">
          <div class="text-nowrap">
            <b-form-checkbox
              :checked="selectedServiceRequests.includes(data.item._id)"
              @change="toggleSelectedServiceRequests(data.item._id)"
              @click.native.stop
            >
              <b-button
                v-if="canViewThisAction('show', 'ServiceRequest')"
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="none"
                class="text-bold-black align-middle detail-view-id"
                :to="{ name: 'operation-service-requests-show', params: { id: data.item._id } }"
              >
                {{ data.item.stringID }}
              </b-button>
              <b-button
                v-else
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="none"
                class="text-bold-black align-middle detail-view-id not-button"
              >
                {{ data.item.stringID }}
              </b-button>
            </b-form-checkbox>
          </div>
        </template>

        <template #cell(workFlow)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.workFlowName }}</span>
            <br>
            <span class="">File No: {{ data.item.fileNo || '-' }}</span>
          </div>
        </template>

        <template #cell(deceased)="data">
          <div class="text-nowrap">
            <span class="">Niche No: {{ data.item.serviceForm.deceasedNiche || '-' }}</span>
            <br>
            <span class="">Pedestal No: {{ data.item.serviceForm.deceasedPedestal || '-' }}</span>
            <br>
            <span class="">Anling Lot No.: {{ data.item.serviceForm.deceasedAngling || '-' }}</span>
          </div>
        </template>

        <template #cell(date)="data">
          <div class="text-nowrap">
            <span class="">{{ dateFormat(data.item.date) }},</span>
            <br>
            <span class="">{{ timeFormatFromDateString(data.item.date) }}</span>
          </div>
        </template>

        <template #cell(location)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.location }}</span>
            <br>
            <span class="">{{ timeFormatFromDateString(data.item.date) }}</span>
          </div>
        </template>

        <template #cell(serviceDescriptionValues)="data">
          <div
            v-if="data.item.showServiceDescription == true"
          >
            <div
              v-for="(opt, key) in data.item.serviceDescriptionValues"
              :key="key"
            >
              <div
                v-if="((Array.isArray(opt.value) && opt.value.length) || opt.value || (opt.children && opt.children.length))"
              >
                <span v-if="opt.children && opt.children.length">
                  <div
                    v-for="(child, indx) in opt.children"
                    :key="indx"
                  >
                    <div
                      v-if="((Array.isArray(child.value) && child.value.length) || child.value)"
                    >
                      <span
                        v-if="Array.isArray(child.value)"
                      >
                        <span
                          v-for="(option, idx) in child.value"
                          :key="idx"
                        >
                          {{ option }}
                          <feather-icon
                            :id="'unique_'+data.item._id+key+idx"
                            icon="AlertCircleIcon"
                            class="ml-50 text-primary"
                            size="14"
                          />
                          <b-tooltip
                            placement="auto"
                            :target="'unique_'+data.item._id+key+idx"
                            :title="child.key"
                          />
                        </span>
                      </span>
                      <span v-else-if="child.value">
                        <span
                          v-if="(child.type && ['increment', 'short-answer', 'long-answer'].includes(child.type))"
                        >
                          {{ child.key }} <span class="green-text">{{ child.value }}</span>
                        </span>
                        <span v-else>{{ child.key }} {{ child.value }}</span>
                      </span>
                    </div>
                  </div>
                </span>
                <span
                  v-if="Array.isArray(opt.value)"
                >
                  <span
                    v-for="(option, idx) in opt.value"
                    :key="idx"
                  >
                    {{ option }}
                    <feather-icon
                      :id="'unique_'+data.item._id+key+idx"
                      icon="AlertCircleIcon"
                      class="ml-50 text-primary"
                      size="14"
                    />
                    <b-tooltip
                      placement="auto"
                      :target="'unique_'+data.item._id+key+idx"
                      :title="opt.key"
                    />
                  </span>
                </span>
                <span v-else-if="opt.value">
                  <span
                    v-if="(opt.type && ['increment', 'short-answer', 'long-answer'].includes(opt.type))"
                  >
                    {{ opt.key }} <span class="green-text">{{ opt.value }}</span>
                  </span>
                  <span v-else>{{ opt.key }} {{ opt.value }}</span>
                </span>
              </div>
            </div>
          </div>
          <div
            v-else-if="data.item.serviceDescription && data.item.serviceDescription.length && !data.item.serviceDescription[0].id"
          >
            <div
              v-for="(opt, key) in data.item.serviceDescription"
              :key="key"
            >
              <div
                v-if="((Array.isArray(opt.value) && opt.value.length) || opt.value)"
              >
                <span
                  v-if="Array.isArray(opt.value)"
                >
                  <span
                    v-for="(option, idx) in opt.value"
                    :key="idx"
                  >
                    {{ option }}
                    <feather-icon
                      :id="'unique_'+data.item._id+key+idx"
                      icon="AlertCircleIcon"
                      class="ml-50 text-primary"
                      size="14"
                    />
                    <b-tooltip
                      placement="auto"
                      :target="'unique_'+data.item._id+key+idx"
                      :title="opt.key"
                    />
                  </span>
                </span>
                <span v-else-if="opt.value && opt.value != 0">
                  <span>{{ opt.key }}</span> <span class="green-text">{{ opt.value }}</span>
                </span>
              </div>
            </div>
          </div>
          <div v-else>
            -
          </div>
        </template>

        <template #cell(agentName)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.agentName || '-' }}</span>
            <br>
            <span class="">{{ data.item.agentCode || '-' }}</span>
            <br>
            <span
              v-if="data.item.agentContact"
              class=""
            >
              (65) {{ data.item.agentContact }}
            </span>
            <span
              v-else
              class=""
            >
              -
            </span>
          </div>
        </template>

        <template #cell(customerName)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.customerName }}</span>
            <br>
            <span
              v-if="data.item.customerContact"
              class=""
            >
              (65) {{ data.item.customerContact }}
            </span>
          </div>
        </template>

        <template #cell(assignees)="data">
          <div
            v-if="data.item.assignees.length"
            class="text-nowrap"
          >
            <span
              v-for="(user, key) in data.item.assignees"
              :key="key"
            >
              {{ user.name }}<span v-if="key < (data.item.assignees.length - 1)">, </span>
            </span>
          </div>
          <div v-else>
            -
          </div>
        </template>

        <template #cell(paymentStatus)="data">
          <div
            class="text-nowrap"
          >
            <b-badge
              :variant="`light-${resolveServiceRequestPaymentStatusVariant(data.item.paymentStatus)}`"
              class="text-capitalize"
            >
              {{ data.item.paymentStatus }}
            </b-badge>
            <!-- <br>
            <span
              class=""
            >
              {{ data.item.paymentRemarks || '-' }}
            </span> -->
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveServiceRequestStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template
          v-if="canViewThisAction('show', 'ServiceRequest') || canViewThisAction('update', 'ServiceRequest')
            || canViewThisAction('update-detail', 'ServiceRequest') || canViewThisAction('update-assignee', 'ServiceRequest')
            || canViewThisAction('update-status', 'ServiceRequest') || canViewThisAction('delete', 'ServiceRequest')"
          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="canViewThisAction('update', 'ServiceRequest')"
              @click="assignSingleRequest(data.item._id, data.item.assignees)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Add Assignee</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('show', 'ServiceRequest')"
              :to="{ name: 'operation-service-requests-show', params: { id: data.item._id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('update', 'ServiceRequest')
                || canViewThisAction('update-detail', 'ServiceRequest') || canViewThisAction('update-assignee', 'ServiceRequest')
                || canViewThisAction('update-status', 'ServiceRequest')"
              :to="{ name: 'operation-service-requests-edit', params: { id: data.item._id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('delete', 'ServiceRequest')"
              @click="deleteEntity(data.item._id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="canViewThisAction('update', 'ServiceRequest')"
              @click="updateSingleRequestPS(data.item._id, data.item.paymentStatus)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Update Payment Status</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>

      <b-table
        v-else
        ref="refAssignedServiceRequestsTable"
        class="position-relative event_list min-height-200"
        :items="fetchServiceRequests"
        responsive
        :fields="requestTableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Role -->
        <template #cell(stringID)="data">
          <div class="text-nowrap">
            <b-form-checkbox
              :checked="selectedServiceRequests.includes(data.item._id)"
              @change="toggleSelectedServiceRequests(data.item._id)"
              @click.native.stop
            >
              <b-button
                v-if="canViewThisAction('show', 'ServiceRequest')"
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="none"
                class="text-bold-black align-middle detail-view-id"
                :to="{ name: 'operation-service-requests-show', params: { id: data.item._id } }"
              >
                {{ data.item.stringID }}
                <br>
                <span class="font_normal">fr.</span>
                <b-button
                  v-if="canViewThisAction('show', 'ServiceForm')"
                  v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                  variant="none"
                  class="text-bold-primary align-middle detail-view-id"
                  :to="{ name: 'operation-service-forms-show', params: { id: data.item.serviceForm._id } }"
                >
                  {{ data.item.serviceFormStringID }}
                </b-button>
                <b-button
                  v-else
                  v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                  variant="none"
                  class="text-bold-primary align-middle detail-view-id not-button"
                >
                  {{ data.item.serviceFormStringID }}
                </b-button>
              </b-button>
              <b-button
                v-else
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="none"
                class="text-bold-black align-middle detail-view-id not-button"
              >
                {{ data.item.stringID }}
                <span class="font_normal">fr.</span>
                <b-button
                  v-if="canViewThisAction('show', 'ServiceForm')"
                  v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                  variant="none"
                  class="text-bold-primary align-middle detail-view-id"
                  :to="{ name: 'operation-service-forms-show', params: { id: data.item.serviceForm._id } }"
                >
                  {{ data.item.serviceFormStringID }}
                </b-button>
                <b-button
                  v-else
                  v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                  variant="none"
                  class="text-bold-primary align-middle detail-view-id not-button"
                >
                  {{ data.item.serviceFormStringID }}
                </b-button>
              </b-button>
            </b-form-checkbox>
          </div>
        </template>

        <template #cell(workFlow)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.workFlowName }}</span>
            <br>
            <span class="">File No: {{ data.item.fileNo || '-' }}</span>
          </div>
        </template>

        <template #cell(deceased)="data">
          <div class="text-nowrap">
            <span class="">Niche No: {{ data.item.serviceForm.deceasedNiche || '-' }}</span>
            <br>
            <span class="">Pedestal No: {{ data.item.serviceForm.deceasedPedestal || '-' }}</span>
            <br>
            <span class="">Anling Lot No.: {{ data.item.serviceForm.deceasedAngling || '-' }}</span>
          </div>
        </template>

        <template #cell(date)="data">
          <div class="text-nowrap">
            <span class="">{{ dateFormat(data.item.date) }},</span>
            <br>
            <span class="">{{ timeFormatFromDateString(data.item.date) }}</span>
          </div>
        </template>

        <template #cell(location)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.location }}</span>
            <br>
            <span class="">{{ timeFormatFromDateString(data.item.date) }}</span>
          </div>
        </template>

        <template #cell(serviceDescriptionValues)="data">
          <div
            v-if="data.item.showServiceDescription == true"
          >
            <div
              v-for="(opt, key) in data.item.serviceDescriptionValues"
              :key="key"
            >
              <div
                v-if="((Array.isArray(opt.value) && opt.value.length) || opt.value || (opt.children && opt.children.length))"
              >
                <span v-if="opt.children && opt.children.length">
                  <div
                    v-for="(child, indx) in opt.children"
                    :key="indx"
                  >
                    <div
                      v-if="((Array.isArray(child.value) && child.value.length) || child.value)"
                    >
                      <span
                        v-if="Array.isArray(child.value)"
                      >
                        <span
                          v-for="(option, idx) in child.value"
                          :key="idx"
                        >
                          {{ option }}
                          <feather-icon
                            :id="'unique_'+data.item._id+key+idx"
                            icon="AlertCircleIcon"
                            class="ml-50 text-primary"
                            size="14"
                          />
                          <b-tooltip
                            placement="auto"
                            :target="'unique_'+data.item._id+key+idx"
                            :title="child.key"
                          />
                        </span>
                      </span>
                      <span v-else-if="child.value">
                        <span
                          v-if="(child.type && ['increment', 'short-answer', 'long-answer'].includes(child.type))"
                        >
                          {{ child.key }} <span class="green-text">{{ child.value }}</span>
                        </span>
                        <span v-else>{{ child.key }} {{ child.value }}</span>
                      </span>
                    </div>
                  </div>
                </span>
                <span
                  v-if="Array.isArray(opt.value)"
                >
                  <span
                    v-for="(option, idx) in opt.value"
                    :key="idx"
                  >
                    {{ option }}
                    <feather-icon
                      :id="'unique_'+data.item._id+key+idx"
                      icon="AlertCircleIcon"
                      class="ml-50 text-primary"
                      size="14"
                    />
                    <b-tooltip
                      placement="auto"
                      :target="'unique_'+data.item._id+key+idx"
                      :title="opt.key"
                    />
                  </span>
                </span>
                <span v-else-if="opt.value">
                  <span
                    v-if="(opt.type && ['increment', 'short-answer', 'long-answer'].includes(opt.type))"
                  >
                    {{ opt.key }} <span class="green-text">{{ opt.value }}</span>
                  </span>
                  <span v-else>{{ opt.key }} {{ opt.value }}</span>
                </span>
              </div>
            </div>
          </div>
          <div
            v-else-if="data.item.serviceDescription && data.item.serviceDescription.length && !data.item.serviceDescription[0].id"
          >
            <div
              v-for="(opt, key) in data.item.serviceDescription"
              :key="key"
            >
              <div
                v-if="((Array.isArray(opt.value) && opt.value.length) || opt.value)"
              >
                <span
                  v-if="Array.isArray(opt.value)"
                >
                  <span
                    v-for="(option, idx) in opt.value"
                    :key="idx"
                  >
                    {{ option }}
                    <feather-icon
                      :id="'unique_'+data.item._id+key+idx"
                      icon="AlertCircleIcon"
                      class="ml-50 text-primary"
                      size="14"
                    />
                    <b-tooltip
                      placement="auto"
                      :target="'unique_'+data.item._id+key+idx"
                      :title="opt.key"
                    />
                  </span>
                </span>
                <span v-else-if="opt.value && opt.value != 0">
                  <span>{{ opt.key }}</span> <span class="green-text">{{ opt.value }}</span>
                </span>
              </div>
            </div>
          </div>
          <div v-else>
            -
          </div>
        </template>

        <template #cell(agentName)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.agentName || '-' }}</span>
            <br>
            <span class="">{{ data.item.agentCode || '-' }}</span>
            <br>
            <span
              v-if="data.item.agentContact"
              class=""
            >
              (65) {{ data.item.agentContact }}
            </span>
            <span
              v-else
              class=""
            >
              -
            </span>
          </div>
        </template>

        <template #cell(customerName)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.customerName }}</span>
            <br>
            <span
              v-if="data.item.customerContact"
              class=""
            >
              (65) {{ data.item.customerContact }}
            </span>
          </div>
        </template>

        <template #cell(assignees)="data">
          <div
            v-if="data.item.assignees.length"
            class="text-nowrap"
          >
            <span
              v-for="(user, key) in data.item.assignees"
              :key="key"
            >
              {{ user.name }}<span v-if="key < (data.item.assignees.length - 1)">, </span>
            </span>
          </div>
          <div v-else>
            -
          </div>
        </template>

        <template #cell(paymentStatus)="data">
          <div
            class="text-nowrap"
          >
            <b-badge
              :variant="`light-${resolveServiceRequestPaymentStatusVariant(data.item.paymentStatus)}`"
              class="text-capitalize"
            >
              {{ data.item.paymentStatus }}
            </b-badge>
            <!-- <br>
            <span
              class=""
            >
              {{ data.item.paymentRemarks || '-' }}
            </span> -->
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveServiceRequestStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template
          v-if="canViewThisAction('show', 'ServiceRequest') || canViewThisAction('update', 'ServiceRequest')
            || canViewThisAction('update-detail', 'ServiceRequest') || canViewThisAction('update-assignee', 'ServiceRequest')
            || canViewThisAction('update-status', 'ServiceRequest') || canViewThisAction('delete', 'ServiceRequest')"
          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="canViewThisAction('update', 'ServiceRequest')"
              @click="assignSingleRequest(data.item._id, data.item.assignees)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Add Assignee</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('show', 'ServiceRequest')"
              :to="{ name: 'operation-service-requests-show', params: { id: data.item._id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('update', 'ServiceRequest')
                || canViewThisAction('update-detail', 'ServiceRequest') || canViewThisAction('update-assignee', 'ServiceRequest')
                || canViewThisAction('update-status', 'ServiceRequest')"
              :to="{ name: 'operation-service-requests-edit', params: { id: data.item._id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('delete', 'ServiceRequest')"
              @click="deleteEntity(data.item._id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="canViewThisAction('update', 'ServiceRequest')"
              @click="updateSingleRequestPS(data.item._id, data.item.paymentStatus)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Update Payment Status</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
    </b-card>

    <div
      v-if="totalServiceRequests > perPage"
      class="mx-2 mb-2"
    >
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center pagination-content"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalServiceRequests"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            align="center"
            prev-text="Prev"
            next-text="Next"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
              <span>Prev</span>
            </template>
            <template #next-text>
              <span>Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

    <b-modal
      id="modal-update-status-assigned-service-request-bulk"
      ref="update-status-modal-assigned-bulk"
      modal-class="status-update-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="updateBulkStatus"
    >
      <b-form @submit.prevent="updateBulkStatus">
        <validation-observer
          ref="assignedServiceRequestsBulkStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-event-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-event-status"
                v-model="bulkStatus"
                label="title"
                :options="statusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="bulkStatusValidation == true ? bulkStatusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="bulkStatusValidation"
                class="text-danger"
              >
                {{ bulkStatusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>
    <b-modal
      id="modal-update-payment-status-open-service-request-bulk"
      ref="update-payment-status-modal-open-bulk"
      modal-class="status-update-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Payment Status"
      @ok="updateBulkPaymentStatus"
    >
      <b-form @submit.prevent="updateBulkPaymentStatus">
        <validation-observer
          ref="openServiceRequestsBulkPaymentStatusEditForm"
        >
          <b-form-group
            label="Payment Status*"
            label-for="h-event-payment-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Payment Status"
              vid="paymentStatus"
              rules="required"
            >
              <v-select
                id="h-event-payment-status"
                v-model="bulkPaymentStatus"
                label="title"
                placeholder="Select from list"
                :options="paymentStatusOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="bulkPaymentStatusValidation == true ? bulkPaymentStatusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="bulkPaymentStatusValidation"
                class="text-danger"
              >
                {{ bulkPaymentStatusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>
    <b-modal
      id="modal-update-assignee-open-service-request-bulk"
      ref="update-assignee-modal-assigned-bulk"
      modal-class="status-update-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Confirm Changes"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Assign Selected Requests"
      @ok="assignBulkServiceRequests"
    >
      <b-card-text>
        If you wish to assign or reassign the requests, please select their name from the list below:
      </b-card-text>
      <b-form @submit.prevent="assignBulkServiceRequests">
        <validation-observer
          ref="assignedServiceRequestsBulkAssigneeEditForm"
        >
          <b-form-group
            label=""
            label-for="h-event-assignee"
          >
            <validation-provider
              #default="{ errors }"
              name="Assignee"
              vid="assignee"
              rules=""
            >
              <v-select
                id="h-event-assignee"
                v-model="bulkAssignee"
                label="name"
                multiple
                :options="assigneeOptions"
                :reduce="name => name._id"
                :clearable="true"
                @input="checkForAssigneeSchedule"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="bulkAssigneeValidation"
                class="text-danger"
              >
                {{ bulkAssigneeError }}
              </small>
            </validation-provider>
          </b-form-group>
          <div
            v-if="assignmentError"
            class="service_assign_error"
          >
            <h5>Warning</h5>
            <div>
              <p>Assingee(s) may not be available for the the following Service Requests. Are you sure you want to proceed?</p>
              <ul>
                <li
                  v-for="(option, index) in assignmentErrorOptions"
                  :key="index"
                >
                  {{ option.date }} - {{ option.request }} - {{ option.name }} ({{ option.leave }})
                </li>
              </ul>
            </div>
          </div>
          <b-form-checkbox
            v-model="confirmVal"
            value="accepted"
            unchecked-value=""
            class="custom-control-primary"
          >
            Send message about these assignments to related service Agent(s)
          </b-form-checkbox>
        </validation-observer>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormInput, BTable, BFormCheckbox, BInputGroup, BInputGroupAppend, BCardText, VBTooltip, BTooltip,
  BBadge, BDropdown, BDropdownItem, BPagination, BForm, BFormGroup, BButton, BFormTag,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

import store from '@/store'

import useAssignedServiceRequestList from './useAssignedServiceRequestList'
import serviceRequestStoreModule from '../serviceRequestStoreModule'
import ServiceRequestFilters from '../ServiceRequestFilters.vue'

export default {
  components: {
    // UserListAddNew,
    BButton,
    BForm,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BFormTag,
    BCardText,
    BTooltip,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,

    ServiceRequestFilters,
    ValidationProvider,
    ValidationObserver,
  },
  directives: { 'b-tooltip': VBTooltip, Ripple },
  props: {
    requestTypeOptions: {
      type: Array,
      required: true,
    },
    assigneeOptions: {
      type: Array,
      required: true,
    },
    parentServiceDate: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      bulkStatus: 'pending',
      bulkStatusValidation: false,
      bulkStatusError: 'Valid status is required',
      bulkPaymentStatus: 'Paid',
      bulkPaymentStatusValidation: false,
      bulkPaymentStatusError: 'Valid status is required',
      bulkAssignee: [],
      assignmentErrorOptions: [],
      assignmentError: false,
      bulkAssigneeValidation: false,
      bulkAssigneeError: 'Valid assignee is required',
      confirmVal: 'accepted',
      statusDataOptions: [
        { title: 'Awaiting Approval', code: 'awaiting approval' },
        { title: 'Completed', code: 'completed' },
        { title: 'Pending', code: 'pending' },
      ],
      paymentStatusOptions: [
        { title: 'Paid', code: 'Paid' },
        { title: 'Partially Paid', code: 'Partially Paid' },
        { title: 'Unpaid', code: 'Unpaid' },
        { title: 'FSP', code: 'FSP' },
        { title: 'N/A', code: 'N/A' },
      ],
      showFilterDropdown: process.env.VUE_APP_SHOW_FILTER_DROPDOWN,

      required,
    }
  },
  watch: {
    parentServiceDate: {
      immediate: true,
      handler(val) {
        if (this.serviceDateFilter !== val) {
          this.serviceDateFilter = val
        }
      },
    },
    serviceDateFilter: {
      immediate: true,
      handler(val) {
        if (this.parentServiceDate !== val) {
          this.$emit('update:parentServiceDate', val)
        }
      },
    },
  },

  methods: {
    resolveServiceRequestPaymentStatusVariant(status) {
      if (status === 'Paid') return 'success'
      if (status === 'Partially Paid') return 'warning'
      if (status === 'Unpaid') return 'danger'
      return 'secondary'
    },
    changeGroupByForm(val) {
      localStorage.setItem('groupByForm', val)
      this.groupByForm = val
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return ''
      if (item.time === 'light') return 'table-light-variant'
      if (item.time === 'dark') return 'table-dark-variant'
      return ''
    },
    hideFilter() {
      this.$refs.filter_dropdown_assigned_service_request.hide(true)
    },
    showFilter() {
      this.$refs.filter_dropdown_assigned_service_request.show(true)
    },
    removeFromStatusFilter(item) {
      const index = this.statusFilter.indexOf(item)
      if (index > -1) {
        this.statusFilter.splice(index, 1)
      }
    },
    removeFromPaymentStatusFilter(item) {
      const index = this.paymentStatusFilter.indexOf(item)
      if (index > -1) {
        this.paymentStatusFilter.splice(index, 1)
      }
    },
    removeFromRequestStatusFilter(item) {
      const index = this.requestStatusFilter.indexOf(item)
      if (index > -1) {
        this.requestStatusFilter.splice(index, 1)
      }
    },
    removeFromRequestTypeFilter(item) {
      const index = this.requestTypeFilter.indexOf(item)
      if (index > -1) {
        this.requestTypeFilter.splice(index, 1)
      }
    },
    removeFromAssigneeFilter(item) {
      const index = this.assigneeFilter.indexOf(item)
      if (index > -1) {
        this.assigneeFilter.splice(index, 1)
      }
    },
    resolveRequestTypeName(id) {
      const request = this.requestTypeOptions.find(o => o._id === id)
      if (request) {
        return request.name || ''
      }
      return ''
    },

    resolveAssigneeName(id) {
      const user = this.assigneeOptions.find(o => o._id === id)
      if (user) {
        return user.name || ''
      }
      return ''
    },

    clearAllFilters() {
      this.dueDateFilter = ''
      this.serviceDateFilter = ''
      this.statusFilter = []
      this.paymentStatusFilter = []
      this.requestStatusFilter = []
      this.requestTypeFilter = []
      this.assigneeFilter = []
    },
    updateBulkStatus() {
      this.$refs.assignedServiceRequestsBulkStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.bulkStatus)
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.selectedServiceRequests.length; i++) {
            formData.append('serviceRequests[]', this.selectedServiceRequests[i])
          }

          this.$http.patch('operation/service-requests/action/bulk/status/update', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(() => {
              this.refetchData()
              this.$emit('emitCountServiceRequest')
              this.$swal({
                title: 'Status Updated!',
                html: 'The status for selected service requests has been updated',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'bulkStatus') {
                    this.bulkStatusError = validationError.msg
                    this.bulkStatusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    updateSingleRequestPS(id, status) {
      // const serviceRequestIndex = this.selectedServiceRequests.indexOf(id)

      // if (serviceRequestIndex === -1) this.selectedServiceRequests.push(id)
      this.selectedServiceRequests = []
      this.selectedServiceRequests.push(id)
      this.bulkPaymentStatus = status
      this.$root.$emit('bv::show::modal', 'modal-update-payment-status-open-service-request-bulk', '')
    },
    updateBulkPaymentStatus() {
      this.$refs.openServiceRequestsBulkPaymentStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('paymentStatus', this.bulkPaymentStatus)
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.selectedServiceRequests.length; i++) {
            formData.append('serviceRequests[]', this.selectedServiceRequests[i])
          }

          this.$http.patch('operation/service-requests/action/bulk/payment-status/update', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(() => {
              this.refetchData()
              this.$emit('emitCountServiceRequest')
              this.$swal({
                title: 'Payment Status Updated!',
                html: 'The payment status for selected service requests has been updated',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'bulkPaymentStatus') {
                    this.bulkPaymentStatusError = validationError.msg
                    this.bulkPaymentStatusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    assignSingleRequest(id, assignees) {
      // const serviceRequestIndex = this.selectedServiceRequests.indexOf(id)

      // if (serviceRequestIndex === -1) this.selectedServiceRequests.push(id)
      this.selectedServiceRequests = []
      this.selectedServiceRequests.push(id)
      this.bulkAssignee = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < assignees.length; i++) {
        this.bulkAssignee.push(assignees[i]._id)
      }
      this.$root.$emit('bv::show::modal', 'modal-update-assignee-open-service-request-bulk', '')
    },
    checkForAssigneeSchedule() {
      // eslint-disable-next-line no-unused-expressions
      this.bulkAssigneeValidation === true ? this.bulkAssigneeValidation = false : null
      const formData = new FormData()
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.bulkAssignee.length; i++) {
        formData.append('bulkAssignee[]', this.bulkAssignee[i])
      }

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.selectedServiceRequests.length; i++) {
        formData.append('serviceRequests[]', this.selectedServiceRequests[i])
      }
      if (this.bulkAssignee.length) {
        this.$http.post('operation/service-requests/check/assignee/schedule', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(response => {
            if (response.data.assigneeWarning.length) {
              this.assignmentErrorOptions = response.data.assigneeWarning
              this.assignmentError = true
              // this.$root.$emit('bv::show::modal', 'modal-update-assignee-all-service-request-bulk', '')
            } else {
              this.assignmentErrorOptions = []
              this.assignmentError = false
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },

    assignBulkServiceRequests(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.assignedServiceRequestsBulkAssigneeEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('confirmVal', this.confirmVal)
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.bulkAssignee.length; i++) {
            formData.append('assignees[]', this.bulkAssignee[i])
          }
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.selectedServiceRequests.length; i++) {
            formData.append('serviceRequests[]', this.selectedServiceRequests[i])
          }

          this.$http.patch('operation/service-requests/action/bulk/assignee/update', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(() => {
              this.refetchData()
              // this.$emit('emitCountServiceRequest')
              this.assignmentErrorOptions = []
              this.assignmentError = false
              this.$root.$emit('bv::hide::modal', 'modal-update-assignee-open-service-request-bulk', '')
              this.$swal({
                title: 'Assignee Updated!',
                html: 'Assignees for selected service requests has been updated',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'assignees') {
                    this.bulkAssigneeError = validationError.msg
                    this.bulkAssigneeValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    deleteBulkServiceRequests() {
      this.$swal({
        title: 'Are you sure?',
        html: 'This will <strong>delete</strong> selected service request(s). This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete!',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < this.selectedServiceRequests.length; i++) {
              formData.append('serviceRequests[]', this.selectedServiceRequests[i])
            }

            this.$http.patch('operation/service-requests/action/bulk/delete', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(() => {
                this.refetchData()
                this.$emit('emitCountServiceRequest')
                this.selectedServiceRequests = []
                // this.$nextTick(() => {
                //   this.$refs['update-status-modal'].toggle('#toggle-btn')
                // })
                this.$swal({
                  title: 'Service Request(s) Deleted!',
                  html: 'Selected service request(s) has been deleted',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    deleteEntity(id) {
      this.$swal({
        title: 'Are You Sure?',
        html: 'Selecting Delete will <strong>permanently delete</strong> this item. This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Delete!',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.toDeletedID = id
            this.deleteServiceRequest()
          }
        })
    },
  },
  setup() {
    const showSearchBar = ref(false)
    const showFilterOptions = ref(false)
    const SERVICE_REQUEST_APP_STORE_MODULE_NAME = 'app-assigned-service-requests'

    // Register module
    if (!store.hasModule(SERVICE_REQUEST_APP_STORE_MODULE_NAME)) store.registerModule(SERVICE_REQUEST_APP_STORE_MODULE_NAME, serviceRequestStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SERVICE_REQUEST_APP_STORE_MODULE_NAME)) store.unregisterModule(SERVICE_REQUEST_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      { label: 'Assigned', value: 'assigned' },
      { label: 'Unassigned', value: 'unassigned' },
    ]

    const { canViewThisAction } = useAclUtils()

    const {
      fetchServiceRequests,
      formTableColumns,
      requestTableColumns,
      perPage,
      currentPage,
      totalServiceRequests,
      serviceRequestData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAssignedServiceRequestsTable,
      refetchData,
      groupByForm,

      toDeletedID,
      deleteServiceRequest,

      // UI
      resolveServiceRequestStatusVariant,

      // Extra Filters
      statusFilter,
      paymentStatusFilter,
      requestStatusFilter,
      categoryFilter,
      dueDateFilter,
      serviceDateFilter,
      requestTypeFilter,
      assigneeFilter,
    } = useAssignedServiceRequestList()

    const selectedServiceRequests = ref([])
    const toggleSelectedServiceRequests = serviceRequestId => {
      const serviceRequestIndex = selectedServiceRequests.value.indexOf(serviceRequestId)

      if (serviceRequestIndex === -1) selectedServiceRequests.value.push(serviceRequestId)
      else selectedServiceRequests.value.splice(serviceRequestIndex, 1)
    }
    const selectAssignedServiceRequestCheckbox = computed(() => serviceRequestData.value.length && (serviceRequestData.value.length === selectedServiceRequests.value.length))
    const isSelectAssignedServiceRequestCheckboxIndeterminate = computed(() => Boolean(selectedServiceRequests.value.length) && serviceRequestData.value.length !== selectedServiceRequests.value.length)
    const selectAllCheckboxUpdate = val => {
      selectedServiceRequests.value = val ? serviceRequestData.value.map(flow => flow._id) : []
    }

    return {

      // Sidebar

      fetchServiceRequests,
      formTableColumns,
      requestTableColumns,
      perPage,
      currentPage,
      totalServiceRequests,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAssignedServiceRequestsTable,
      refetchData,
      showSearchBar,
      showFilterOptions,
      toDeletedID,
      deleteServiceRequest,
      serviceRequestData,
      groupByForm,

      // Filter
      avatarText,
      canViewThisAction,

      // UI
      resolveServiceRequestStatusVariant,

      statusOptions,

      // Extra Filters
      statusFilter,
      paymentStatusFilter,
      requestStatusFilter,
      categoryFilter,
      dueDateFilter,
      serviceDateFilter,
      requestTypeFilter,
      assigneeFilter,

      selectAssignedServiceRequestCheckbox,
      isSelectAssignedServiceRequestCheckboxIndeterminate,
      selectedServiceRequests,
      toggleSelectedServiceRequests,
      selectAllCheckboxUpdate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
